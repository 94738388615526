<template>
  <div class="aea-main">
    <v-col v-if="nonet">
      <v-row>
        <v-col cols="12" class="d-flex justify-center text-center pt-10">
          <v-img lazy-src="../assets/nonet.png" width="200" max-width="250" src="../assets/nonet.png" cols="12"></v-img>
        </v-col>
        <v-col cols="12" class="justify-center text-center">
          <h4 cols="12">Connect to the internet</h4>
          <p>You're offline. Check your connection</p>
          <v-btn text color="primary" @click="$router.go()"> Retry </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-card>
        <v-card-title class="primary--text">
          <h3>All Employees</h3>
          <v-spacer class="d-block d-md-none"></v-spacer>
          <v-btn class="ms-3" link to="/employees/all" color="primary--text">
            <v-icon> mdi-arrow-left </v-icon>
            <span class="d-none d-md-block">back </span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
          </v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="employees" :search="search">
          <!-- <template v-slot:item.is_fuel="{ item }">
            <div v-if="parseInt(item.is_fuel)">Yes</div>
            <div v-else>No</div>
          </template> -->
          <template v-slot:item.age="{ item }">
            <div>{{ currentAge(item.date_of_birth) }}</div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn text color="primary" class="mx-0 px-0" dark @click="editItem(item.id)">
              Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <v-btn class="mb-3 ml-3 mt-3">
        <export-excel :data="employees" :name="excel_name" :fields="excel_headders">
          Download Excel
        </export-excel>
      </v-btn>
    </v-col>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "Dashboard",
  components: {
    // TabBar,
  },
  data() {
    return {
      excel_name:
        "Employees" +
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10) +
        ".xls",
      nonet: false,
      search: "",
      headers: [
        { text: "Employee No", value: "employee_no", align: "start" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Designation", value: "designation" },
        { text: "NIC No", value: "nic_no" },
        { text: "Primary E-mail", value: "primary_email" },
        { text: "Secondary E-mail", value: "secondary_email" },
        { text: "Primary Mobile", value: "primary_mobile_no" },
        { text: "Secondary Mobile", value: "secondary_mobile_no" },
        { text: "Fixed No", value: "fixed_no" },
        { text: "Date of Joined", value: "date_of_joined" },
        { text: "Date of Birth", value: "date_of_birth" },
        { text: "Age at Joining", value: "age_at_joining" },
        { text: "Current Age", value: "age" },
        { text: "Blood Group", value: "blood_group" },
        { text: "Gender", value: "gender" },
        { text: "Nick Name", value: "nick_name" },
        { text: "Address 1", value: "address_1" },
        { text: "Address 2", value: "address_2" },
        { text: "City", value: "city" },
        { text: "State", value: "state" },
        { text: "Country", value: "country" },
        { text: "Postal", value: "postal_code" },
        { text: "Left Date", value: "left_date" },
        { text: "Action", value: "actions", sortable: false, align: "end" },
      ],
      excel_headders: {
        "Employee No": "employee_no",
        "First Name": "first_name",
        "Last Name": "last_name",
        "Designation": "designation",
        "NIC No": "nic_no",
        "Primary E-mail": "primary_email",
        "Secondary E-mail": "secondary_email",
        "Primary Mobile": "primary_mobile_no",
        "Secondary Mobile": "secondary_mobile_no",
        "Fixed No": "fixed_no",
        "Date of Joined": "date_of_joined",
        "Date of Birth": "date_of_birth",
        "Age at Joining": "age_at_joining",
        "Blood Group": "blood_group",
        "Gender": "gender",
        "Nick Name": "nick_name",
        "Address 1": "address_1",
        "Address 2": "address_2",
        "City": "city",
        "State": "state",
        "Country": "country",
        "Postal": "postal_code",
        "Left Date": "left_date",
      },

      print_values: [],
      employees: [],
      snackbar: false,
      text: ``,
      timeout: 3000,
    };
  },
  methods: {
    editItem(id) {
      this.$router.push("/employees/edit/" + id);
    },
    currentAge(dob) {
      let ageInMilliseconds = new Date() - new Date(dob);
      return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
    },
  },
  mounted() {
    if (
      !(
        JSON.parse(localStorage.imso_user).role == 1 ||
        JSON.parse(localStorage.imso_user).role == 5
      )
    ) {
      this.$router.push("/");
    }
    axios
      .get(this.$backend_url + "employees-admin", {
        params: {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.employees = response.data.employees;
        }
        this.nonet = false;
      })
      .catch((res) => {
        console.log("Errors:", res);
        this.nonet = true;
        this.snackbar = true;
        this.text = `No Internet`;
      });
  },
};
</script>

<style>
.aea-main {
  padding: 20px;
  margin-left: 50px;
  width: calc(100vw - 60px);
  min-height: calc(100vh - 200px);
}

@media only screen and (max-width: 960px) {
  .aea-main {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .aea-main {
    padding: 20px;
    margin-left: 0px;
    width: 100vw;
  }

  .aea-main table>tbody>tr>td:nth-child(1),
  .aea-main table>thead>tr>th:nth-child(1) {
    background: transparent !important;
    border-right: none !important;
  }
}

.aea-main table>tbody>tr>td:nth-child(1),
.aea-main table>thead>tr>th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  background: white;
  border-right: 2px solid rgb(211, 211, 211);
}
</style>